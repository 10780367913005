/* Popup Overlay */
.custom-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Popup Content */
.custom-popup {
    background: white;
    padding: 70px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
}

/* Buttons */
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.custom-confirm-button {
    background: #457EFF;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
}

.custom-cancel-button {
    background: #ccc;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}


/* Text in the Popup */
.custom-popup-text {
    font-size: 20px; /* Adjust the font size as needed */
    margin: 0; /* Remove any default margin */
}


