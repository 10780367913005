/* CSS for the Popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above other content */
    visibility: hidden; /* Initially hidden */
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }
  
  .popup.open {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s linear;
  }
  
  .popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 300px;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fffdfd;
  }
  
  h2 {
    margin: 0 0 20px;
  }
  
  label {
    display: block;
    margin: 10px 0;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    margin-top: 5px;
  }
  
  button {
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }
  

  