.author-info {
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  }
  

  
.author-info h3{
    color: #0AB5FF;
    font-size: 1.2rem;
    font-weight: bolder;
  }


