/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'inter', sans-serif;
  }
  
  /* Define a container for your content */
  .container {
    max-width: 1140px;
    margin: auto;
    padding: 20px;
  }
  
  /* Define a card-like style for your form */
  .form-card {
    background-color: white;
    border: 1px solid #C9C9C9;
    border-radius: 20px;
    padding: 20px;
    color: #454545;
  }
  
  /* Apply styles to labels */
  .form-card label {
    margin-bottom: 10px;
    font-weight: bold;
    color: #454545;
  }
  
  .backButton{
    
      text-decoration: none;
      padding: 10px 20px;
      border-radius: 5px;
    margin-right: 10px;
      background-color: #457EFF;;
      color: #ffffff;
    
    
  }
  /* Apply styles to input fields */
  .form-card input[type='text'],
  .form-card input[type='number'],
  .form-card textarea,
  .form-card select,
  .form-card input[type='datetime-local'] {
    width: 100%;
    padding: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    font-size: 1rem;
    color: #454545;
    margin-top: 1rem;
    outline: none; /* Remove input outline */
  }

  /* CSS for the grey button */
.button-grey {
  background-color: grey;
  color: white; /* Text color */
  font-size: 1rem;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px

}

/* CSS for the blue button */
.button-blue {
  background-color:  #457EFF;
  color: white; /* Text color */
  font-size: 1rem;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px
  
}

  
  /* Apply styles to the button */
  .form-card .button {
    font-size: 1rem;
    background-color: #F4F4F4;
    padding: 10px;
    border: none;
    color: #C9C9C9;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .form-card .button-light {
    border: 1px solid #457EFF;
    background-color: white;
    color: #457EFF;
  }
  
  .form-card .button-dark {
    background-color: #457EFF;
    color: white;
  }
  
  /* Add spacing between elements */
  .form-card > * {
    margin: 1rem 0;
  }
  
  /* Additional styling can be added as needed */
  /* Display the URL section on a single line */
/* Display the entire URL section on a single line */
.form-card .url-section {
    display: inline-flex;
    align-items: center;
  }
  
  /* Apply styles to the label in the URL section */
  .form-card .url-section label {
    margin-right: 1rem;
  }
  
  /* Apply styles to the input field in the URL section */
  .form-card .url-section input[type='text'] {
    flex: 1; /* Allow the input field to expand and take up available space */
  }
  
  

  /* Back Button Styles */
.back-button {
 
  background-color:  #457EFF;;
  color: #ffffff;
  font-size: 1rem;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; /* Add margin to create a gap between buttons */
}

.back-button-light {
  border: 1px solid #457EFF;
  background-color: white;
  color: #457EFF;
}

.back-button-dark {
  background-color: #457EFF;
  color: white;
}

/* Add Page Button Styles */
.add-page-button {
  font-size: 1rem;
  background-color: #457EFF;
  padding: 10px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px; /* Add margin to create a gap between buttons */
}

/* Apply margin to the buttons if needed */
.button-margin {
  margin: 0 1rem; /* Adjust the margin as needed */
}


/* popup */

/* CSS for the "Open Profile" button */
/* CSS for the "Open Profile" button */
/* CSS for the "Open Profile" button */
.open-profile-button {
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 5px 10px; /* Adjust the padding to control button size */
  font-size: 14px;
  cursor: pointer;
  width: 100px; /* Adjust the width as desired */
  margin-left: auto; /* Move the button to the right side */
}

.open-profile-button:hover {
  background: #0056b3;
}

.blog-component-card{
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}
.author-info{
    padding: 20px;
    border-radius: 9px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10); 
}


.author-info {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.author-info img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid #0AB5FF;
}

.author-info h3,.author-image-desc h3,.author-image-desc-3 h3{
  color: #0AB5FF;
  font-size: 1.2rem;
  font-weight: bolder;
}
.author-info p{
  color: #757575;
  font-size: 8px;
}
.board {
  width: 1100px;
  min-height: 500px;
  border: 1px solid rgb(50,50,50,0.2);
  overflow: auto;
}
.board>*{
  margin: 1rem;
}
aside{
  position: fixed;
  top:0;
  right: 0;
}

.card2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background-color: rgb(255, 232, 77);
  height: 500px;
  width: 400px;
}

.card2>*{
  margin-top: 10px;
}

.card2 h3{
  font-size: 1.6rem;
}

.card2 a{
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
}