/* Login.css */

/* Style for the Login container */
.login-container {
    margin-bottom: 100px;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Style for the form elements */
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Error message style */
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Button styles */
  .login-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Style for the "Login with Google" button */
  .google-login-button {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Hover effect for buttons */
  .login-button:hover,
  .google-login-button:hover {
    background-color: #0056b3;
  }
  