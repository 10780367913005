* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'inter', sans-serif;
    letter-spacing: -1.192px;
    font-size: 14px;
}

.containers {
    max-width: 1440px;
    margin: auto;
}
.card{
    caret-color:red;
}

.flex {
    display: flex;
    align-items: center;
}

.card {
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;

}

.card,
.top-card {
    padding: 15px;
    border-bottom: 1px solid #D3E1FF;
    color: #757575;
}

.top-card {
    justify-content: space-between;
    display: flex;
    justify-content: space-between;
}

.card-right .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.top-card .buttons-others p,
.selectors .buttons-others p {
    font-weight: 600;
    cursor: pointer;
}

.selectors .buttons-others {

    margin-left: 3rem;
}

.selectors .buttons-others .draft {
    padding: 10px 40px;
    margin-right: 5px;

}


.review-button-selected {
    border-bottom: 2px solid #FEBC07;

}

.draft-button-selected {
    border-bottom: 2px solid #afafaf;
}

.published-button-selected {
    border-bottom: 2px solid #00A82D;
}


.deleted-button-selected {

    border-bottom: 2px solid rgb(238, 70, 70);
}

.all-button-selected {
    border-bottom: 2px solid #457EFF;
}


.selectors .buttons-others .selected-item {
    color: #1579FF;
    border-bottom: 2px solid #1579FF;
}

.selectors {
    border-bottom: 1px solid #D3E1FF;
    padding-bottom: 1rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.buttons-others button {
    /* font-size: 1rem; */
    margin-right: 1rem;
    border: none;
    color: #A6A6A6;
    background-color: #F4F4F4;
    padding: 5px;
}

.top-card .buttons-others {
    margin-left: -1rem;
}

.top-card .buttons-others>* {
    margin-right: 5px;
}

.buttons-others {
    align-self: flex-start;
}

.card-right .buttons-others {
    font-size: 14px !important;
}


.draft {
    color: #757575;
    background-color: #F9F9F9;
    padding: 10px 20px;

}

.filters-input .key p {
    color: #457EFF;
    font-size: 1rem;
}

.filters-input .key {
    margin-left: 5px;
}

.key,
.add-plus {
    display: flex;
    align-items: center;
}

.key>* {
    margin-right: 4px;
}

.top-card div {
    display: flex;
}

.search input {
    background-image: url('images/search.png');
    background-size: 20px 20px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding: 5px 20px;
    border: 2px solid #457EFF;
    font-size: 1rem;
    border-radius: 5px;
}

.search input::placeholder {
    color: #457EFF;

}

.add-plus p {
    margin-right: 20px;
}

.link {
    text-decoration: none;
    color: white;
}



.add-plus {
    color: white;
    background-color: #457EFF;
    padding: 5px 10px;
    border-radius: 8px;
    margin-left: 10px;
}

.filters-input .checkbox {
    position: relative;
    top: -5px;
}

.checkbox input[type='checkbox']::before {
    position: absolute;
    left: -1px;
    top: 3px;
    font-size: 20px;
    font-weight: bold;
    line-height: 12px;
    color: #00A82D;
    transform: scale(0);
    transition: 0.3s all ease-in-out;
    z-index: 1;

}


.checkbox input[type='checkbox']:checked::before {
    transform: scale(1);
    content: '\2713';
    z-index: 1;
}
[contenteditable="true"] {
    border: none;
    outline: none; /* Remove the outline as well, which is often styled by browsers */
    }


.checkbox input[type='checkbox']:checked {
    border: 2px solid #00A82D;
}

.checkbox input[type='checkbox'] {
    appearance: none;
    border: 2px solid #757575;
    background-color: white;
    position: relative;
    z-index: 0;
    width: 20px;
    height: 20px;
    top: 5px;
    cursor: pointer;
}

.bread-crumb {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    align-self: flex-start;
    width: 100%;
    justify-content: space-between;
}

.heading-checkbox {
    color: #757575;
    display: flex;
    align-items: baseline;
}

.heading-checkbox>* {
    margin-right: 1rem;
}

.heading-checkbox input {
    margin-right: 10px;

}

.card-left .checkbox {
    margin-right: 1rem;

}

.filters .flex {
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
}

.filters {
    margin-left: 4rem;
    display: flex;
    align-items: center;
    color: #457EFF;
}

.filters .flex p {
    margin: 0 10px;
}

.filters-input label {
    color: #457EFF;
}

.filters-input select {
    width: 100px;
    border: none;
    border-radius: 10px;
    background-color: #ECF2FF;
    padding: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 10px;
    color: #457EFF;
}

.filters-input {
    display: flex;
    margin: 10px 0;
    border-bottom: 1px solid #D3E1FF;
    padding: 10px;
}


.arrows {
    display: flex;
    flex-direction: column;
   
}

.card-right p {
    font-size: 14px;
}

.card-right {
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
}

.card-left {
    display: flex;
}

.card-left img {
    height: 83px;
    width: 138px;
}

.card .heading {
    color: #454545;
    font-family: Verdana, sans-serif;
    font-weight: 800;
    margin-bottom: 10px;
    color: #457EFF;
}

.seo {
    text-decoration: none;
    display: flex;
    font-weight: 600;
    align-items: center;
    color: #757575;
}

.seo>* {
    margin-right: 1rem;
}

.edit {

    background-color: #ECF2FF;
    color: #457EFF;
    padding: 5px;
}

.bread-crum {

    display: flex;
    color: #757575;
    align-items: center;
    align-self: flex-start;
    width: 680px;
    justify-content: space-between;
}

.crumb {
    color: #C9C9C9
}

.bread-crum>* {
    margin-right: 1rem;
}

.bread-crum span {
    color: #457EFF;
}

.words {
    align-self: flex-end;
    color: #757575;
    font-weight: 600;
    font-size: 14px;
}

.review-select {
    color: #FEBC07 !important;
    background-color: white !important;
}

.published-select {
    color: #00A82D !important;
    background-color: white !important;
}

.draft-select {
    color: #757575 !important;
    background-color: white !important;
}

.review {
    color: #FEBC07;
    background-color: #FFF9EA;
    padding: 10px 20px;
}

.published {
    color: #00A82D;
    background-color: #EFFFF3;
    padding: 10px 20px;
}

.deleted {

    color: rgb(238, 70, 70);
    background-color:white;
    padding: 10px 20px;

}

.all {
    padding: 10px 20px;
    background-color: #ECF2FF;
    color: #457EFF;
}

.percent-green {
    background-color: #C6FFD5;
}

.percent-yellow {
    background-color: #FFF1CB;
}

.percent-red {
    background-color: #FFCBBC;
}

.percent {
    padding: 5px;
    text-align: center;
}
 select option {
    -webkit-cursor:pointer;
    cursor: pointer;
    
}

.selectors select {
    cursor: pointer;
    border: none;
    font-size: 1.5rem;
    color: #457EFF;
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 10px;
    margin-left: 10px;
}

.char {
    font-weight: 600;
    margin-top: -10px;
}

.char-green {
    color: #40B05E;
}

.char-red {
    color: #FF3A00;
}

.filters {
    margin-left: 6rem;
}


.setting p {
    font-weight: 600;
    font-size: 18px;
    color: #457EFF;
}

.icons-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.svgLink {
    text-decoration: none;
    color: #457EFF
}


.icons-flex .icon {
    margin-right: 1rem;
    border: 2px solid #FFC24C;
    border-radius: 50%;
    padding: 5px;
}

.icons-flex p {
    font-size: 10px;
    font-weight: 700;
    color: #454545;
    letter-spacing: 0.1px;
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
}

.icons-flex .active {
    background-color: #FFC24C;
}