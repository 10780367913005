/* SignUp.css */

/* Style for the SignUp container */
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Style for the form elements */
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Error message style */
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Button styles */
  .signup-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* Hover effect for buttons */
  .signup-button:hover {
  
    background-color: #0056b3;
  }
  